import React, { useEffect, useState } from "react"
import { Link } from 'gatsby'
import queryString from 'query-string'

import axios from 'axios'
import { FormattedMessage } from "react-intl"
import { Breadcrumb, message } from 'antd'

import Seo from '../../components/seo'
import Layout from "../../components/layout"
import WLoading from "../../components/wLoading"
import { timeFormat } from '../../utils/methods'

import '../../style/security.scoped.scss'

const CveDetail = ({ pageContext, location }) => {
  const id = queryString.parse(location.search).id

  const pageLang = pageContext.lang
  const pagePrefix = pageLang === 'en' ? '/en' : ''
  const apiPrefix = process.env && process.env.NODE_ENV === 'development' ? 'http://jenkins.mogdb.enmotech.com:7004/api' : '/api'
  const [detailData, setDetailData] = useState({})
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true)
    const res = await axios.get(apiPrefix + '/public/security/' + id);
    setLoading(false)
    if (res && res.data && res.data.code === 200) {
      const result = res.data.data || {}
      setDetailData(result)
    } else {
      message.error(res.data && res.data.message || 'error');
    }
  }, []);

  function getContent (key) {
    return replaceUnmatchedBrackets(detailData[pageLang === 'zh' ? `${key}Zh` : key] || detailData.key)
  }
  function replaceUnmatchedBrackets(str) {  
    if (!str) return str
    const regx = /<(?![^<>]*>)/g;
    return str.replace(regx, '&lt;');
  }

  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
      <Seo title="MogDB: Cve" />
      <div className="w-banner-container">
        <div className="w-banner-box"><FormattedMessage id="secutity.pageTitle" /></div>
      </div>
      <WLoading spinning={loading}>
        <div className="security-container">
          <div className="security-info">
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href={`${pagePrefix}/cve/`}>CVE</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item><FormattedMessage id="secutity.detail" /></Breadcrumb.Item>
            </Breadcrumb>
            <h2 className="title">{detailData.name}</h2>
            <p><FormattedMessage id="cveDeatil.releaseDate" />：{detailData.releaseDate}</p><p><FormattedMessage id="cveDeatil.updateDate" />：{timeFormat(detailData.updatedTime, 'YYYY-MM-DD HH:mm:ss')}</p>
            <div className="cve-detail-content">
              <div>
                <h3><FormattedMessage id="cveDeatil.title1" /></h3>
                <p className="js-cve-summary" dangerouslySetInnerHTML={{ __html: getContent('summary') }}></p>
              </div>
              <div>
                <h3>CVSS v3 <FormattedMessage id="cveDeatil.title2" /></h3>
                <div className="w-li-table cve-li-table">
                  <ul className="pack-title">
                    <li></li>
                    <li>NVD</li>
                    <li>MogDB</li>
                  </ul>
                  {detailData.indicators && detailData.indicators.map((item, idx) =>
                    <ul className="pack-content" key={idx}>
                      <li>{item.name}</li>
                      <li>{item.NVD}</li>
                      <li>{item.MogDB.toUpperCase()}</li>
                    </ul>)}
                </div>
                <br />
                <h3><FormattedMessage id="cveDeatil.title3" /></h3>
                <div className="w-li-table cve-li-table">
                  <ul className="pack-title">
                    <li><FormattedMessage id="cveDeatil.tableCol1" /></li>
                    <li><FormattedMessage id="cveDeatil.tableCol2" /></li>
                    <li><FormattedMessage id="cveDeatil.tableCol3" /></li>
                  </ul>
                  {detailData.link && detailData.link.map((item, idx) =>
                    <ul className="pack-content" key={idx}>
                      <li><Link to={`${pagePrefix}/advisory/detail?id=${item.id}`} className="link-text">{item.name}</Link></li>
                      <li>{item.desc}</li>
                      <li>{item.date}</li>
                    </ul>)}
                </div>
                <br />
                <h3><FormattedMessage id="cveDeatil.title4" /></h3>
                <div className="w-li-table cve-li-table">
                  <ul className="pack-title">
                    <li><FormattedMessage id="cveDeatil.tableCol4" /></li>
                    <li><FormattedMessage id="cveDeatil.tableCol5" /></li>
                    <li><FormattedMessage id="cveDeatil.tableCol6" /></li>
                  </ul>
                  {detailData.products && detailData.products.map((item, idx) =>
                    <ul className="pack-content" key={idx}>
                      <li>{item.name}</li>
                      <li>{item.package}</li>
                      <li>{item.status}</li>
                    </ul>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </WLoading>
    </Layout>
  )
}
export default CveDetail